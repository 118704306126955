<template>
  <div class="login bg-black h-screen flex flex-col items-center justify-center gap-3 text-white">
    <!-- <BienvenidoAlRock class="w-40" /> -->

    <div class="heading text-gradient text-center">
      <h1>BIENVENIDO</h1>
      <h1>AL ROCK</h1>
    </div>

    <ArrowsDown class="w-8" />

    <form @submit.prevent="submit" class="flex flex-col items-center gap-1">
      <input v-model="mail" type="email" name="email" autocomplete="username" />
      <label for="email" class="mb-2">MAIL RR GROUP</label>

      <input v-model="password" type="password" name="password" autocomplete="current-password" />
      <label for="password" class="mb-16">CONTRASEÑA</label>

      <button type="submit" class="text-gradient text-xl">
        <BtnSeguir class="w-36" />
      </button>
    </form>

    <LoginFailedModal v-if="showErrorModal" @close="closeModal" />
  </div>
</template>

<script>
import { isDirector } from "@/utils/roles"
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ArrowsDown from '@/components/shared/icons/ArrowsDown.vue'
import BtnSeguir from '@/components/shared/icons/BtnSeguir.vue'
import LoginFailedModal from '@/components/login/LoginFailedModal.vue'

export default {
  name: "Login",
  components: { ArrowsDown, BtnSeguir, LoginFailedModal },
  data() {
    return {
      mail: '',
      password: '',
      showErrorModal: false
    }
  },

  computed: {
    ...mapGetters({ user: 'user/user' })
  },

  created() {
    this.$store.dispatch('user/getUser', 'preventErrorToastOnce')
      .then(() => {
        const user = this.$store.getters['user/user']
        if (user) {
          if (isDirector(this.user.roles)) {
            this.$router.push({ name: 'Director' })
          } else {
            this.$router.push({ name: 'Recurso' })
          }
        } else {
          this.setActiveLayout('LoginLayout')
        }
      })
  },

  beforeDestroy() {
    this.setActiveLayout('MainLayout')
  },

  methods: {
    ...mapActions({ login: 'user/login' }),
    ...mapMutations({ setActiveLayout: 'layout/setActiveLayout' }),

    async submit() {
      const { success } = await this.login({mail:this.mail, password:this.password})

      if (success) {
        if (isDirector(this.user.roles)) {
          this.$router.push({ name: 'Director' })
        } else {
          const termsAccepted = localStorage.getItem('rtd-accepted-terms')
          const routeName = termsAccepted ? 'Recurso' : 'Bienvenida'
          this.$router.push({ name: routeName })
        }
      }
    },

    closeModal() {
      this.showErrorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  font-family: WestmountExtraBold;
}

.heading {
  font-size: 1.75rem;
  letter-spacing: -1px;
  h1 {
    line-height: 1;
  }
}

input {
  @apply py-1 px-3 rounded-lg text-black w-48;
}
</style>
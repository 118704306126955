<template>
  <div>
    <img src="../svg/arrows-down.svg" />
  </div>
</template>

<script>
export default {
  name: 'ArrowsDown'
}
</script>
<template>
  <div
    class="fixed top-0 w-screen h-screen flex items-center justify-center"
    @click.self="$emit('close')"
  >
    <div class="content relative">
      <div class="w-3 absolute top-4 right-4 cursor-pointer" @click="$emit('close')">
        <XIcon />
      </div>
      <UpsidedownSmile class="w-12" />
      <h3 class="text-2xl text-center">INGRESO INCORRECTO</h3>
      <p class="text-sm text-center">
        SOLICITA SOPORTE TÉCNICO A ALEXIS@RRGROUPHUB.COM O WHATSAPP/58624818
      </p>
    </div>
  </div>
</template>

<script>
import UpsidedownSmile from '@/components/shared/icons/UpsidedownSmile.vue'
import XIcon from '@/components/shared/icons/XIcon.vue'
export default {
  name: 'LoginFailedModal',
  components: { UpsidedownSmile, XIcon }
}
</script>

<style scoped>
.content {
  @apply w-5/6 text-black rounded-xl flex flex-col items-center justify-center gap-5 pt-5 px-3;
  max-width: 280px;
  min-height: 60vh;
  background: rgba(255, 255, 255, 0.9);
  font-family: HelveticaLt;
}
</style>

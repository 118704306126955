<template>
  <div>
    <img src="../png/btn-seguir.png" alt="SEGUIR" />
  </div>
</template>

<script>
export default {
  name: 'BtnSeguir'
}
</script>